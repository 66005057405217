import { MealPlanActionTypes } from "./mealplan.actionTypes";

const INITIAL_STATE = {
    customizedMenu: {}
};

const mealPlanReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MealPlanActionTypes.SET_CUSTOMIZED_MENU: {
            const { mealPlanId, menu } = action.payload;
            return {
                ...state,
                customizedMenu: {
                    ...state.customizedMenu,
                    [mealPlanId]: menu
                }
            };
        }
        case MealPlanActionTypes.UPDATE_CUSTOMIZED_MENU_ITEM: {
            const { mealPlanId, mealType, mealFamily, updatedItem } = action.payload;

            // Check if the meal plan exists in the state
            const existingPlan = state.customizedMenu[mealPlanId];

            // If the plan doesn't exist, there's nothing to update
            if (!existingPlan) {
                return state;
            }

            // Update the specific item within the meal type array
            const updatedMealTypeItems = existingPlan[mealType].map(item =>
                item.family === mealFamily ? updatedItem : item
            );

            // Construct the updated state
            const updatedState = {
                ...state.customizedMenu,
                [mealPlanId]: {
                    ...existingPlan,
                    [mealType]: updatedMealTypeItems
                }
            };

            return {
                ...state,
                customizedMenu: updatedState
            };
        }
        case MealPlanActionTypes.CLEAR_CUSTOMIZED_MENU:
            return {
                ...state,
                customizedMenu: {}
            };
        default:
            return state;
    }
};

export default mealPlanReducer;

