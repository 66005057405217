import React, { forwardRef } from 'react';
import './styles.css';

const Banner = forwardRef(({ handleScrollMealPlans }, ref) => (
    <main ref={ref}>
        <header>
            <div className='header-content'>
                <div className='content-main'>
                    <h1 className='company-name'>Mokka Food</h1>
                    <p className='company-info'>
                        Freshly prepared 5-day weekly meals <br /> for North East London <br /> and part of Essex
                    </p>
                    <img className='header-mobile-img' src='/images/banner.png' alt='banner' />
                    <button className='view-plans-button' onClick={handleScrollMealPlans}>
                        View Meal Plans <i className='fas fa-long-arrow-alt-right'></i>
                    </button>
                </div>
            </div>
            <img className='header-img' src='/images/banner.png' alt='banner' />
        </header>
    </main>
));

export default Banner;
