import { createSelector } from 'reselect';

const selectCart = (state) => state.cart;

export const selectCartItems = createSelector(
    [selectCart],
    (cart) => cart.cartItems,
);

export const selectCartItemsCount = createSelector(
    [selectCartItems],
    (cartItems) =>
        cartItems?.filter(
            (cartItem) =>
                cartItem.type === 'meal-plan',
        ).reduce(
            (accumulatedQuantity, cartItem) =>
                accumulatedQuantity + cartItem.quantity,
            0,
        ),
);

export const selectCartTotal = createSelector([selectCartItems], (cartItems) =>
    cartItems?.filter(
        (cartItem) =>
            cartItem.price != null,
    ).reduce(
        (accumulatedQuantity, cartItem) =>
            accumulatedQuantity + cartItem.quantity * cartItem.price,
        0,
    ).toFixed(2),
);
