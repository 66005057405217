import React from 'react';
import './styles.css';

const MealPlanItem = ({ item, handleOpenMealPlan }) => {
    const { id, title, minCalories, maxCalories, imageUrl, price } = item;

    const Info = () => {
        if (maxCalories === minCalories) {
            return (
                <p className='meal-plan-info'>
                    <span>{minCalories} Calories</span>
                </p>
            );
        } else {
            return (
                <p className='meal-plan-info'>
                    <span>{minCalories} - {maxCalories} Calories</span>
                </p>
            );
        }
    };

    return (
        <div className='item-meal-plan'>
            <img className='item-image' src={imageUrl} alt='food' />
            <div className='item-head_desc'>
                <p className='meal-plan-name'>{title}</p>
                <Info />
            </div>
            <div className='item-add-button-div'>
            <p className='item-meal-plan-price'>£{(price / 100).toFixed(2)}</p>
                <button className='meal-plan-button' onClick={() => handleOpenMealPlan(id)}>
                    Open Me<i className='fa-solid fa-utensils'></i>
                </button>
            </div>
        </div>
    );
};

export default MealPlanItem;
