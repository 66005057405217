import React from 'react';
import './styles.css';

const LoadingPopUp = ({ isLoadingPopUpShown }) => {
    return (
        isLoadingPopUpShown === true ?
            <div className='modal-are-you-sure'>
                <div className='modal-content-loading modal-content-are-you-sure'>
                    <h1 className='loading-h1 are-you-sure-h1'>Processing payment...</h1>
                    <p className='loading-p do-not-close'><i className="fa-solid fa-triangle-exclamation"></i> Do not close this window!</p>
                    <i className='loading-icon fa fa-circle-notch'></i>
                </div>
            </div> :
            null
    );
};

export default LoadingPopUp;
