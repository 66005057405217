import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import cartReducer from './cart/cart.reducer';
import userReducer from './user/user.reducer';
import mealPlanReducer from './mealplan/mealplan.reducer';

const persistConfig = {
    key: 'mokkafood-co-uk',
    storage,
    whitelist: ['cart', 'user', 'mealplan'],
};

const appReducer = combineReducers({
    cart: cartReducer,
    user: userReducer,
    mealplan: mealPlanReducer,
});

export default persistReducer(persistConfig, appReducer);
