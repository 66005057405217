import React, { useEffect } from 'react';
import MenuItem from './MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomizedMenu } from '../../../redux/mealplan/mealplan.action';
import { selectMealPlanTypeById } from '../../../redux/mealplan/mealplan.selector';
import './styles.css';

export const MealConsumptionType = Object.freeze({
    BREAKFAST: 'Breakfast',
    LUNCH: 'Lunch',
    DINNER: 'Dinner',
});

const Menu = ({ openItemDetails, mealPlanItem }) => {
    const dispatch = useDispatch();

    const breakfastItems = useSelector(selectMealPlanTypeById(mealPlanItem.id, MealConsumptionType.BREAKFAST)) || [];
    const lunchItems = useSelector(selectMealPlanTypeById(mealPlanItem.id, MealConsumptionType.LUNCH)) || [];
    const dinnerItems = useSelector(selectMealPlanTypeById(mealPlanItem.id, MealConsumptionType.DINNER)) || [];

    const menuAlreadyCustomized = useSelector((state) => !!state.mealplan.customizedMenu[mealPlanItem.id]);

    useEffect(() => {
        if (!menuAlreadyCustomized) {
            dispatch(
                setCustomizedMenu(mealPlanItem.id, {
                    Breakfast: calculateMealOptions(mealPlanItem.meals.breakfast),
                    Lunch: calculateMealOptions(mealPlanItem.meals.lunch),
                    Dinner: calculateMealOptions(mealPlanItem.meals.dinner),
                })
            );
        }
    }, [breakfastItems, lunchItems, dinnerItems, mealPlanItem, dispatch, menuAlreadyCustomized]);

    const calculateMealOptions = (meals) => {
        let processedItems = [];

        meals.forEach((item) => {
            if (item.family) {
                const familyItems = meals
                    .filter(
                        (familyItem) =>
                            familyItem.family === item.family &&
                            !processedItems.find((processedItem) => processedItem.title === familyItem.title)
                    )
                    .map((optionItem) => ({
                        id: optionItem.id,
                        title: optionItem.optionTitle,
                        imageUrl: optionItem.imageUrl,
                        preferred: optionItem.preferredOption,
                        calories: optionItem.calories,
                    }));

                if (familyItems.length > 0) {
                    // Determine the preferred option
                    let preferredOption = familyItems.find((option) => option.preferred);
                    if (!preferredOption) {
                        // If no preferred option, set the first option as preferred
                        preferredOption = familyItems[0];
                    }

                    const newItem = {
                        id: item.family,
                        family: item.family,
                        title: item.title,
                        description: item.description,
                        selectedOption: preferredOption,
                        options: familyItems,
                    };
                    processedItems.push(newItem);
                }
            } else {
                processedItems.push(item);
            }
        });

        return processedItems;
    };

    return (
        <main className='menu-main'>
            <div className='breakfast-div'>
                {breakfastItems.map((item, index) => (
                    <MenuItem
                        mealPlanId={mealPlanItem.id}
                        openItemDetails={openItemDetails}
                        item={item}
                        dayOfWeekIndex={index}
                        mealConsumptionType={MealConsumptionType.BREAKFAST}
                        key={item.id}
                    />
                ))}
            </div>

            <div className='lunch-div'>
                {lunchItems.map((item, index) => (
                    <MenuItem
                        mealPlanId={mealPlanItem.id}
                        openItemDetails={openItemDetails}
                        item={item}
                        dayOfWeekIndex={index}
                        mealConsumptionType={MealConsumptionType.LUNCH}
                        key={item.id}
                    />
                ))}
            </div>

            <div className='dinner-div'>
                {dinnerItems.map((item, index) => (
                    <MenuItem
                        mealPlanId={mealPlanItem.id}
                        openItemDetails={openItemDetails}
                        item={item}
                        dayOfWeekIndex={index}
                        mealConsumptionType={MealConsumptionType.DINNER}
                        key={item.id}
                    />
                ))}
            </div>
        </main>
    );
};

export default Menu;
