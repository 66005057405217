import mixpanel from 'mixpanel-browser';
mixpanel.init('655fa5e378bf3a8b014a25103d687ee8');

const actions = {
    track: (name, props) => {
        mixpanel.track(name, props);
    },
};

export const Mixpanel = actions;
