import React from 'react';
import './styles.css';

const Logo = () => (
    <div className='header-content_logo'>
        <div>
            <span>
                <b>© 2022 Mokka Food</b>
            </span>
        </div>
    </div>
);

export default Logo;
