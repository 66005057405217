import React from 'react';
import './styles.css';

const DoubleItemInCartPopUp = ({ isDisplayed, mealPlanName, handleAddOneMore, closePopUp }) => {
    return isDisplayed === true ? (
        <div className='modal-are-you-sure'>
            <div className='modal-double-item-content modal-content-are-you-sure'>
                <button className='modal-close-button-post-code' onClick={closePopUp}>
                    <i className='fas fa-times'></i>
                </button>
                <h1 className='double-item-h1 are-you-sure-h1'>Are you sure?</h1>
                <p className='double-item-p'>
                    You&apos;ve already added a <b>{mealPlanName}</b> to your cart.
                </p>
                <div className='double-item-buttons-div are-you-sure-buttons-div'>
                    <button className='double-item-yes-button are-you-sure-yes-button' onClick={handleAddOneMore}>
                        <i className='fa-solid fa-cart-plus button-icon'></i> Yes, add one more.
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default DoubleItemInCartPopUp;
