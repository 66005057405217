import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateCustomizedMenuItem } from '../../../../redux/mealplan/mealplan.action';
import './styles.css';

const MenuItem = ({ mealPlanId, openItemDetails, item, dayOfWeekIndex, mealConsumptionType, updateCustomizedMenuItem }) => {
    const [selectedOption, setSelectedOption] = useState(item.selectedOption || null);

    useEffect(() => {
        setSelectedOption(item.selectedOption);
    }, [item.selectedOption]);

    const handleChange = (event) => {
        const newSelectedOption = item.options?.find((option) => option.title === event.target.value);
        if (newSelectedOption) {
            const newItem = { ...item, selectedOption: newSelectedOption };
            updateCustomizedMenuItem(mealPlanId, mealConsumptionType, item.family, newItem);
            setSelectedOption(newSelectedOption);
        }
    };

    const OptionList = () => {
        if (item.options && item.options.length > 0) {
            return (
                <div className='option-list-div desktop'>
                    <select value={selectedOption?.title || ''} onChange={handleChange}>
                        {item.options.map((option, index) => (
                            <option key={index} value={option.title}>
                                {option.title}
                            </option>
                        ))}
                    </select>
                    <p>
                        <i className='fa-solid fa-shuffle'></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const MobileOptionList = () => {
        if (selectedOption != null) {
            return (
                <span className='head_desc-mobile-customization'>
                    <p className='head_desc-customization'>with:</p>
                    <p className='head_desc-customization-title'>
                        {selectedOption?.title} <i className='fa-solid fa-shuffle'></i>
                    </p>
                </span>
            );
        } else {
            return null;
        }
    };

    function dayOfWeek() {
        switch (dayOfWeekIndex) {
            case 0:
                return 'Monday';
            case 1:
                return 'Tuesday';
            case 2:
                return 'Wednesday';
            case 3:
                return 'Thursday';
            case 4:
                return 'Friday';
            case 5:
                return 'Saturday';
            case 6:
                return 'Sunday';
            default:
                return null;
        }
    }

    return (
        <div className='item'>
            <div onClick={() => openItemDetails(mealPlanId, mealConsumptionType, item)} className='item-image-div'>
                <img className='item-image' src={selectedOption?.imageUrl || item.imageUrl} importance='high' alt='menu-item' />
                <i className='item-meal-plan-expand-icon fa fa-up-right-and-down-left-from-center'></i>
            </div>
            <div onClick={() => openItemDetails(mealPlanId, mealConsumptionType, item)} className='item-head_desc'>
                <p className='head_desc-name'>{item.title}</p>
                <p className='head_desc-info'>{item.description}</p>
            </div>
            <MobileOptionList />
            <OptionList />
            <div className='item-calories-div'>
                <p className='item-calories'>
                    <b>{selectedOption?.calories || item.calories || '-'}</b> Calories
                </p>
                {item.family != null ? <p className='tap-to-customize'>Tap to customise</p> : null}
            </div>
            <div className='day-of-week-div'>
                <p className='day-of-week-p'>
                    <b>{dayOfWeek()}</b>
                </p>
                <p className='meal-consumption-type-p'>{mealConsumptionType}</p>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateCustomizedMenuItem: (mealPlanId, mealType, mealFamily, updatedItem) =>
        dispatch(updateCustomizedMenuItem(mealPlanId, mealType, mealFamily, updatedItem)),
});

export default connect(null, mapDispatchToProps)(MenuItem);
