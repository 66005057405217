import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { updateCustomizedMenuItem } from '../../../../redux/mealplan/mealplan.action';
import './styles.css';

const MenuItemPopUpDetails = ({ closeItemDetails, mealPlanId, mealConsumptionType, item, updateCustomizedMenuItem }) => {
    const [selectedOption, setSelectedOption] = useState(item?.selectedOption || null);

    useEffect(() => {
        setSelectedOption(item?.selectedOption);
    }, [item]);

    const handleChange = (event) => {
        const newSelectedOption = item.options?.find((option) => option.title === event.target.value);
        if (newSelectedOption) {
            const newItem = { ...item, selectedOption: newSelectedOption };
            updateCustomizedMenuItem(mealPlanId, mealConsumptionType, item.family, newItem);
            setSelectedOption(newSelectedOption);
        }
    };

    const OptionList = () => {
        if (item.options && item.options.length > 0) {
            return (
                <div className='option-list-div-details'>
                    <select value={selectedOption?.title || ''} onChange={handleChange}>
                        {item.options.map((option, index) => (
                            <option key={index} value={option.title}>
                                {option.title}
                            </option>
                        ))}
                    </select>
                    <p>
                        <i className='fa-solid fa-shuffle'></i>
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    return item ? (
        <div className='modal'>
            <div className='modal-content'>
                <button className='modal-close-button' onClick={closeItemDetails}>
                    <i className='fa-solid fa-xmark'></i> Close
                </button>
                <img
                    className='menu-pop-up-img'
                    src={selectedOption?.imageUrl || item.imageUrl || item.selectedOption.imageUrl}
                    alt='meal-img'
                ></img>
                <div className='meal-content-div'>
                    <h1 className='meal-name'>{item.title}</h1>
                    <p className='meal-info'>{item.description}</p>
                    <OptionList />
                    <div className='pop-up-item-calories-div'>
                        <p className='pop-up-item-calories'>
                            <b>{selectedOption?.calories || item.calories || item.selectedOption.calories}</b> Calories
                        </p>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

const mapDispatchToProps = (dispatch) => ({
    updateCustomizedMenuItem: (mealPlanId, mealType, mealFamily, updatedItem) =>
        dispatch(updateCustomizedMenuItem(mealPlanId, mealType, mealFamily, updatedItem)),
});

export default connect(null, mapDispatchToProps)(MenuItemPopUpDetails);
