import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCartItemsCount } from '../../../redux/cart/cart.selector';
import './styles.css';

const ButtonCartCount = ({ cartCount, handleOpenCart }) => {
    return (
        <div className='btnCartCount' onClick={handleOpenCart}>
            <div className='count'>{cartCount == null ? 0 : (cartCount >= 100 ? '99+' : cartCount)}</div>
            <i className='fas fa-shopping-cart'></i>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
});

export default connect(mapStateToProps)(ButtonCartCount);
