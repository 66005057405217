export const menuItemsData = [
    // LOW CALORIE PLAN
    {
        id: 101,
        name: 'Picasso Super Breakfast',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 412,
        img: '/images/breakfast/picasso-super-breakfast.jpg',
        mobileImg: '/images/mobile/breakfast/picasso-super-breakfast-mobile.jpg',
    },
    {
        id: 102,
        name: 'Chia Nutri Garden',
        type: 'menu-item',
        info: ['Let us take you in a lovely garden this morning!'],
        category: 'breakfast',
        options:
    {
        idsAndNames: { 1: 'Mokka', 2: 'Kiwi', 3: 'Berries' },
        calories: [0, 412, 0],
        images: ['/images/breakfast/chia-nutri-garden-mokka.jpg',
            '/images/breakfast/chia-nutri-garden-kiwi.jpg',
            '/images/breakfast/chia-nutri-garden-berries.jpg'],
        mobileImages: ['/images/mobile/breakfast/chia-nutri-garden-mokka-mobile.jpg',
            '/images/mobile/breakfast/chia-nutri-garden-kiwi-mobile.jpg',
            '/images/mobile/breakfast/chia-nutri-garden-berries-mobile.jpg'],
    },
        calories: 412,
        selectedOption: 1,
        img: '/images/breakfast/chia-nutri-garden-mokka.jpg',
        mobileImg: '/images/mobile/breakfast/chia-nutri-garden-mokka-mobile.jpg',
    },
    {
        id: 103,
        name: 'Eggs Wrap Wake Up',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 382,
        img: '/images/breakfast/eggs-wrap-wake-up.jpg',
        mobileImg: '/images/mobile/breakfast/eggs-wrap-wake-up-mobile.jpg',
    },
    {
        id: 104,
        name: 'Overnight Oats',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 343,
        img: '/images/breakfast/overnight_oats.jpeg',
        mobileImg: '/images/mobile/breakfast/overnight_oats-mobile.jpg',
    },
    {
        id: 105,
        name: 'Pancakes',
        type: 'menu-item',
        info: ['with (choose an option):'],
        category: 'breakfast',
        options:
    {
        idsAndNames: { 1: 'Blueberries', 2: 'Potato Oats, Ham, Kefir', 3: 'Oats, Protein' },
        calories: [361, 398, 361],
        images: ['/images/breakfast/blueberry_pancakes.jpeg',
            '/images/unavailable.jpg',
            '/images/breakfast/good-protein-morning.jpg'],
        mobileImages: ['/images/mobile/breakfast/blueberry_pancakes-mobile.jpg',
            '/images/unavailable.jpg',
            '/images/mobile/breakfast/good-protein-morning-mobile.jpg'],
    },
        calories: 361,
        img: '/images/breakfast/blueberry_pancakes.jpeg',
        mobileImg: '/images/mobile/breakfast/blueberry_pancakes-mobile.jpg',
    },
    {
        id: 201,
        name: 'Sea Bream with Bulgur',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 620,
        img: '/images/dinner/dinner-1.jpg',
        mobileImg: '/images/mobile/dinner/dinner-1-mobile.jpg',
    },
    {
        id: 202,
        name: 'Low-cooked Pork with Veggies',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 784,
        img: '/images/lunch/lunch-1.jpg',
        mobileImg: '/images/mobile/lunch/lunch-1-mobile.jpg',
    },
    {
        id: 203,
        name: 'Beef with Sweet Potatoes',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 786,
        img: '/images/lunch/beef-with-sweet-potatoes.jpg',
        mobileImg: '/images/mobile/lunch/beef-with-sweet-potatoes-mobile.jpg',
    },
    {
        id: 204,
        name: 'Meat Balls',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 691,
        img: '/images/lunch/meat_balls.jpg',
        mobileImg: '/images/lunch/meat_balls.jpg',
    },
    {
        id: 205,
        name: 'Chicken Curry',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 740,
        img: '/images/lunch/chicken-curry.jpg',
        mobileImg: '/images/mobile/lunch/chicken-curry-mobile.jpg',
    },
    {
        id: 301,
        name: 'Sea Salad',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        options:
    {
        idsAndNames: { 1: 'Tuna', 2: 'Shrimps' },
        calories: [612, 612],
        images: ['/images/dinner/tuna_salad.jpg',
            '/images/dinner/shrimp_salad.jpeg'],
        mobileImages: ['/images/dinner/tuna_salad.jpg',
            '/images/mobile/dinner/shrimp_salad-mobile.jpg'],
    },
        category: 'dinner',
        calories: 612,
        img: '/images/dinner/tuna_salad.jpeg',
        mobileImg: '/images/dinner/tuna_salad.jpg',
    },
    {
        id: 302,
        name: 'Chicken thighs',
        type: 'menu-item',
        info: ['with Beetroot Salad, Carrots and Apples'],
        category: 'dinner',
        calories: 730,
        img: '/images/dinner/chicken_thigh_beetroot_salad.jpeg',
        mobileImg: '/images/mobile/dinner/chicken_thigh_beetroot_salad-mobile.jpg',
    },
    {
        id: 303,
        name: 'Seafood',
        type: 'menu-item',
        info: ['with (choose an option):'],
        category: 'dinner',
        options:
    {
        idsAndNames: { 1: 'Bulgur', 2: 'Rice', 3: 'Veggies' },
        calories: [525, 525, 525],
        images: ['/images/unavailable.jpg',
            '/images/unavailable.jpg',
            '/images/unavailable.jpg'],
        mobileImages: ['/images/unavailable.jpg',
            '/images/unavailable.jpg',
            '/images/unavailable.jpg'],
    },
        calories: 525,
        img: '/images/unavailable.jpg',
        mobileImg: '/images/unavailable.jpg',
    },
    {
        id: 304,
        name: 'Caesar Salad',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        category: 'dinner',
        calories: 629,
        img: '/images/dinner/dinner-3.jpg',
        mobileImg: '/images/mobile/dinner/dinner-3-mobile.jpg',
    },
    {
        id: 305,
        name: 'Beef Burger with Grilled Veggies',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        category: 'dinner',
        calories: 644,
        img: '/images/dinner/beef_burger_grilled_vegetables.jpeg',
        mobileImg: '/images/mobile/dinner/beef_burger_grilled_vegetables-mobile.jpg',
    },
    // BALANCED PLAN
    {
        id: 111,
        name: 'Picasso Super Breakfast',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 612,
        img: '/images/breakfast/picasso-super-breakfast.jpg',
        mobileImg: '/images/mobile/breakfast/picasso-super-breakfast-mobile.jpg',
    },
    {
        id: 112,
        name: 'Chia Nutri Garden',
        type: 'menu-item',
        info: ['Let us take you in a lovely garden this morning!'],
        category: 'breakfast',
        options:
    {
        idsAndNames: { 1: 'Mokka', 2: 'Kiwi', 3: 'Berries' },
        calories: [0, 712, 0],
        images: ['/images/breakfast/chia-nutri-garden-mokka.jpg',
            '/images/breakfast/chia-nutri-garden-kiwi.jpg',
            '/images/breakfast/chia-nutri-garden-berries.jpg'],
        mobileImages: ['/images/mobile/breakfast/chia-nutri-garden-mokka-mobile.jpg',
            '/images/mobile/breakfast/chia-nutri-garden-kiwi-mobile.jpg',
            '/images/mobile/breakfast/chia-nutri-garden-berries-mobile.jpg'],
    },
        calories: 712,
        selectedOption: 1,
        img: '/images/breakfast/chia-nutri-garden-mokka.jpg',
        mobileImg: '/images/mobile/breakfast/chia-nutri-garden-mokka-mobile.jpg',
    },
    {
        id: 113,
        name: 'Eggs Wrap Wake Up',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 582,
        img: '/images/breakfast/eggs-wrap-wake-up.jpg',
        mobileImg: '/images/mobile/breakfast/eggs-wrap-wake-up-mobile.jpg',
    },
    {
        id: 114,
        name: 'Overnight Oats',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 543,
        img: '/images/breakfast/overnight_oats.jpeg',
        mobileImg: '/images/mobile/breakfast/overnight_oats-mobile.jpg',
    },
    {
        id: 115,
        name: 'Pancakes',
        type: 'menu-item',
        info: ['with (choose an option):'],
        category: 'breakfast',
        options:
    {
        idsAndNames: { 1: 'Blueberries', 2: 'Potato Oats, Ham, Kefir', 3: 'Oats, Protein' },
        calories: [361, 598, 361],
        images: ['/images/breakfast/blueberry_pancakes.jpeg',
            '/images/unavailable.jpg',
            '/images/breakfast/good-protein-morning.jpg'],
        mobileImages: ['/images/mobile/breakfast/blueberry_pancakes-mobile.jpg',
            '/images/unavailable.jpg',
            '/images/mobile/breakfast/good-protein-morning-mobile.jpg'],
    },
        calories: 598,
        selectedOption: 1,
        img: '/images/breakfast/blueberry_pancakes.jpeg',
        mobileImg: '/images/mobile/breakfast/blueberry_pancakes-mobile.jpg',
    },
    {
        id: 211,
        name: 'Sea Bream with Bulgur',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 820,
        img: '/images/dinner/dinner-1.jpg',
        mobileImg: '/images/mobile/dinner/dinner-1-mobile.jpg',
    },
    {
        id: 212,
        name: 'Low-cooked Pork with Veggies',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 884,
        img: '/images/lunch/lunch-1.jpg',
        mobileImg: '/images/mobile/lunch/lunch-1-mobile.jpg',
    },
    {
        id: 213,
        name: 'Beef with Sweet Potatoes',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 986,
        img: '/images/lunch/beef-with-sweet-potatoes.jpg',
        mobileImg: '/images/mobile/lunch/beef-with-sweet-potatoes-mobile.jpg',
    },
    {
        id: 214,
        name: 'Meat Balls',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 691,
        img: '/images/lunch/meat_balls.jpg',
        mobileImg: '/images/lunch/meat_balls.jpg',
    },
    {
        id: 215,
        name: 'Chicken Curry',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 940,
        img: '/images/lunch/chicken-curry.jpg',
        mobileImg: '/images/mobile/lunch/chicken-curry-mobile.jpg',
    },
    {
        id: 311,
        name: 'Sea Salad',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        options:
    {
        idsAndNames: { 1: 'Tuna', 2: 'Shrimps' },
        calories: [812, 812],
        images: ['/images/dinner/tuna_salad.jpg',
            '/images/dinner/shrimp_salad.jpeg'],
        mobileImages: ['/images/dinner/tuna_salad.jpg',
            '/images/mobile/dinner/shrimp_salad-mobile.jpg'],
    },
        category: 'dinner',
        calories: 812,
        img: '/images/dinner/tuna_salad.jpeg',
        mobileImg: '/images/dinner/tuna_salad.jpg',
    },
    {
        id: 312,
        name: 'Chicken thighs',
        type: 'menu-item',
        info: ['with Beetroot Salad, Carrots and Apples'],
        category: 'dinner',
        calories: 930,
        img: '/images/dinner/chicken_thigh_beetroot_salad.jpeg',
        mobileImg: '/images/mobile/dinner/chicken_thigh_beetroot_salad-mobile.jpg',
    },
    {
        id: 313,
        name: 'Seafood',
        type: 'menu-item',
        info: ['with (choose an option):'],
        category: 'dinner',
        options:
    {
        idsAndNames: { 1: 'Bulgur', 2: 'Rice', 3: 'Veggies' },
        calories: [820, 820, 820],
        images: ['/images/unavailable.jpg',
            '/images/unavailable.jpg',
            '/images/unavailable.jpg'],
        mobileImages: ['/images/unavailable.jpg',
            '/images/unavailable.jpg',
            '/images/unavailable.jpg'],
    },
        calories: 820,
        img: '/images/unavailable.jpg',
        mobileImg: '/images/unavailable.jpg',
    },
    {
        id: 314,
        name: 'Caesar Salad',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        category: 'dinner',
        calories: 729,
        img: '/images/dinner/dinner-3.jpg',
        mobileImg: '/images/mobile/dinner/dinner-3-mobile.jpg',
    },
    {
        id: 315,
        name: 'Beef Burger with Grilled Veggies',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        category: 'dinner',
        calories: 644,
        img: '/images/dinner/beef_burger_grilled_vegetables.jpeg',
        mobileImg: '/images/mobile/dinner/beef_burger_grilled_vegetables-mobile.jpg',
    },
    // MUSCLE GAIN PLAN
    {
        id: 121,
        name: 'Picasso Super Breakfast',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 712,
        img: '/images/breakfast/picasso-super-breakfast.jpg',
        mobileImg: '/images/mobile/breakfast/picasso-super-breakfast-mobile.jpg',
    },
    {
        id: 122,
        name: 'Chia Nutri Garden',
        type: 'menu-item',
        info: ['Let us take you in a lovely garden this morning!'],
        category: 'breakfast',
        options:
    {
        idsAndNames: { 1: 'Mokka', 2: 'Kiwi', 3: 'Berries' },
        calories: [0, 712, 0],
        images: ['/images/breakfast/chia-nutri-garden-mokka.jpg',
            '/images/breakfast/chia-nutri-garden-kiwi.jpg',
            '/images/breakfast/chia-nutri-garden-berries.jpg'],
        mobileImages: ['/images/mobile/breakfast/chia-nutri-garden-mokka-mobile.jpg',
            '/images/mobile/breakfast/chia-nutri-garden-kiwi-mobile.jpg',
            '/images/mobile/breakfast/chia-nutri-garden-berries-mobile.jpg'],
    },
        calories: 712,
        selectedOption: 1,
        img: '/images/breakfast/chia-nutri-garden-mokka.jpg',
        mobileImg: '/images/mobile/breakfast/chia-nutri-garden-mokka-mobile.jpg',
    },
    {
        id: 123,
        name: 'Eggs Wrap Wake Up',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 682,
        img: '/images/breakfast/eggs-wrap-wake-up.jpg',
        mobileImg: '/images/mobile/breakfast/eggs-wrap-wake-up-mobile.jpg',
    },
    {
        id: 124,
        name: 'Overnight Oats',
        type: 'menu-item',
        info: ['Delicious breakfast meal!'],
        category: 'breakfast',
        calories: 643,
        img: '/images/breakfast/overnight_oats.jpeg',
        mobileImg: '/images/mobile/breakfast/overnight_oats-mobile.jpg',
    },
    {
        id: 125,
        name: 'Pancakes',
        type: 'menu-item',
        info: ['with (choose an option):'],
        category: 'breakfast',
        options:
    {
        idsAndNames: { 1: 'Blueberries', 2: 'Potato Oats, Ham, Kefir', 3: 'Oats, Protein' },
        calories: [461, 398, 461],
        images: ['/images/breakfast/blueberry_pancakes.jpeg',
            '/images/unavailable.jpg',
            '/images/breakfast/good-protein-morning.jpg'],
        mobileImages: ['/images/mobile/breakfast/blueberry_pancakes-mobile.jpg',
            '/images/unavailable.jpg',
            '/images/mobile/breakfast/good-protein-morning-mobile.jpg'],
    },
        calories: 598,
        selectedOption: 1,
        img: '/images/breakfast/blueberry_pancakes.jpeg',
        mobileImg: '/images/mobile/breakfast/blueberry_pancakes-mobile.jpg',
    },
    {
        id: 221,
        name: 'Sea Bream with Bulgur',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 820,
        img: '/images/dinner/dinner-1.jpg',
        mobileImg: '/images/mobile/dinner/dinner-1-mobile.jpg',
    },
    {
        id: 222,
        name: 'Low-cooked Pork with Veggies',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 984,
        img: '/images/lunch/lunch-1.jpg',
        mobileImg: '/images/mobile/lunch/lunch-1-mobile.jpg',
    },
    {
        id: 223,
        name: 'Beef with Sweet Potatoes',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 986,
        img: '/images/lunch/beef-with-sweet-potatoes.jpg',
        mobileImg: '/images/mobile/lunch/beef-with-sweet-potatoes-mobile.jpg',
    },
    {
        id: 224,
        name: 'Meat Balls',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 691,
        img: '/images/lunch/meat_balls.jpg',
        mobileImg: '/images/lunch/meat_balls.jpg',
    },
    {
        id: 225,
        name: 'Chicken Curry',
        type: 'menu-item',
        info: ['Delicious lunch meal!'],
        category: 'lunch',
        calories: 940,
        img: '/images/lunch/chicken-curry.jpg',
        mobileImg: '/images/mobile/lunch/chicken-curry-mobile.jpg',
    },
    {
        id: 321,
        name: 'Sea Salad',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        options:
    {
        idsAndNames: { 1: 'Tuna', 2: 'Shrimps' },
        calories: [812, 812],
        images: ['/images/dinner/tuna_salad.jpg',
            '/images/dinner/shrimp_salad.jpeg'],
        mobileImages: ['/images/dinner/tuna_salad.jpg',
            '/images/mobile/dinner/shrimp_salad-mobile.jpg'],
    },
        category: 'dinner',
        calories: 812,
        img: '/images/dinner/tuna_salad.jpeg',
        mobileImg: '/images/dinner/tuna_salad.jpg',
    },
    {
        id: 322,
        name: 'Chicken thighs',
        type: 'menu-item',
        info: ['with Beetroot Salad, Carrots and Apples'],
        category: 'dinner',
        calories: 930,
        img: '/images/dinner/chicken_thigh_beetroot_salad.jpeg',
        mobileImg: '/images/mobile/dinner/chicken_thigh_beetroot_salad-mobile.jpg',
    },
    {
        id: 323,
        name: 'Seafood',
        type: 'menu-item',
        info: ['with (choose an option):'],
        category: 'dinner',
        options:
    {
        idsAndNames: { 1: 'Bulgur', 2: 'Rice', 3: 'Veggies' },
        calories: [825, 825, 825],
        images: ['/images/unavailable.jpg',
            '/images/unavailable.jpg',
            '/images/unavailable.jpg'],
        mobileImages: ['/images/unavailable.jpg',
            '/images/unavailable.jpg',
            '/images/unavailable.jpg'],
    },
        calories: 825,
        img: '/images/unavailable.jpg',
        mobileImg: '/images/unavailable.jpg',
    },
    {
        id: 324,
        name: 'Caesar Salad',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        category: 'dinner',
        calories: 829,
        img: '/images/dinner/dinner-3.jpg',
        mobileImg: '/images/mobile/dinner/dinner-3-mobile.jpg',
    },
    {
        id: 325,
        name: 'Beef Burger with Grilled Veggies',
        type: 'menu-item',
        info: ['Delicious dinner meal!'],
        category: 'dinner',
        calories: 844,
        img: '/images/dinner/beef_burger_grilled_vegetables.jpeg',
        mobileImg: '/images/mobile/dinner/beef_burger_grilled_vegetables-mobile.jpg',
    },
];

export const extraItemsData = [
    {
        id: 401,
        name: 'Sauce',
        type: 'extra-menu-item',
        info: ['Delicious sauce!'],
        img: '/images/breakfast/picasso-super-breakfast.jpg',
        mobileImg: '/images/mobile/breakfast/picasso-super-breakfast-mobile.jpg',
    },
    {
        id: 402,
        name: 'Bread',
        type: 'extra-menu-item',
        info: ['Delicious bread!'],
        img: '/images/breakfast/picasso-super-breakfast.jpg',
        mobileImg: '/images/mobile/breakfast/picasso-super-breakfast-mobile.jpg',
    },
];
