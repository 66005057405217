import React from 'react';
import './styles.css';

const AreYouSurePopUp = ({ areYouSure, handleYes, handleNo, description }) => {
    return (
        areYouSure === true ?
            <div className='modal-are-you-sure'>
                <div className='modal-content-are-you-sure'>
                    <h1 className='are-you-sure-h1'>Are you sure?</h1>
                    <p>{description}</p>
                    <div className='are-you-sure-buttons-div'>
                        <button className='are-you-sure-yes-button' onClick={handleYes}>
                            <i className="fa button-icon fa-trash-arrow-up"></i> Yes, do it.
                        </button>
                        <button className='are-you-sure-no-button' onClick={handleNo}>
                            <i className="fa button-icon fa-hand"></i> No, stop!
                        </button>
                    </div>
                </div>
            </div> :
            null
    );
};

export default AreYouSurePopUp;
