import React from 'react';
import { useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import { forwardRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Mixpanel } from '../../components/Mixpanel/Mixpanel.jsx';
import { cartAddItem, cartRemoveItem } from '../../redux/cart/cart.action';
import { selectCartItems, selectCartItemsCount } from '../../redux/cart/cart.selector';
import { selectMealPlanById } from '../../redux/mealplan/mealplan.selector';
import DoubleItemInCartPopUp from '../../components/common/DoubleItemInCartPopUp';
import MenuItemPopUpDetails from '../../components/common/Menu/MenuItemPopUpDetails';
import ThankYouPopUp from '../../components/common/ThankYouPopUp';
import Footer from '../../components/common/Footer';
import Menu from '../../components/common/Menu';
import './styles.css';

const MealPlanMenu = forwardRef((props) => {
    const [itemDetailsPopUp, setItemDetailsPopUp] = useState({ mealPlanId: null, mealConsumptionType: null, item: null });
    const [thankYouPopUp, setThankYouPopUp] = useState(null);
    const [doubleItemInCartPopUp, setDoubleItemInCartPopUp] = useState(false);

    const customisedMealPlan = useSelector(selectMealPlanById(getMealPlan().id));
    const history = useHistory();

    function openItemDetails(mealPlanId, mealConsumptionType, item) {
        setItemDetailsPopUp({ mealPlanId, mealConsumptionType, item });
    }

    function closeItemDetails() {
        setItemDetailsPopUp({ mealPlanId: null, mealConsumptionType: null, item: null });
    }

    function getMealPlan() {
        return props.location.state[0];
    }

    function getMealPlanCalories() {
        const minCalories = getMealPlan().minCalories;
        const maxCalories = getMealPlan().maxCalories;

        if (minCalories === maxCalories) {
            return minCalories + ' Cal';
        } else {
            return minCalories + ' - ' + maxCalories + ' Cal';
        }
    }

    function addMealPlanToCart(shouldBypassAlreadyAddedCheck) {
        Mixpanel.track('Button Tapped: Add To Cart', {
            page: 'MealPlanMenu',
        });

        if (!shouldBypassAlreadyAddedCheck) {
            for (let i = 0; i < props.cartList.length; i++) {
                if (props.cartList[i].name === customisedMealPlan.name) {
                    Mixpanel.track('Operation: Display DoubleItemInCart PopUp', {
                        page: 'MealPlanMenu',
                    });
                    setDoubleItemInCartPopUp(true);
                    return;
                }
            }
        }

        const mealPlanInfo = getMealPlan();
        const mealPlanForCart = customisedMealPlan;
        mealPlanForCart.id = uuidv1();
        mealPlanForCart.type = mealPlanInfo.type;
        mealPlanForCart.title = mealPlanInfo.title;
        mealPlanForCart.price = mealPlanInfo.price;
        mealPlanForCart.imageUrl = mealPlanInfo.imageUrl;
        mealPlanForCart.minCalories = mealPlanInfo.minCalories;
        mealPlanForCart.maxCalories = mealPlanInfo.maxCalories;
        props.cartAddItem(mealPlanForCart);

        // for (let i = 0; i < menuItemsData.length; i++) {
        //     if (mealPlanForCart.mealIDs.includes(menuItemsData[i].id)) {
        //         const mealItemForCart = {
        //             ...menuItemsData[i],
        //         };

        //         mealItemForCart.id = uuidv1();
        //         props.cartAddItem(mealItemForCart);
        //     }
        // }

        Mixpanel.track('Operation: Meal plan added to cart successfully.', {
            page: 'MealPlanMenu',
        });

        setThankYouPopUp(getMealPlan());
    }

    function addOneMore() {
        setDoubleItemInCartPopUp(false);
        addMealPlanToCart(true);
    }

    // function resetMenuItemCustomization(menuItem) {
    //     delete menuItem.selectedOption;
    //     delete menuItem.selectedImage;

    //     if (menuItem.calories != null && menuItem.options != null) {
    //         if (menuItem.options.calories[0] != null) {
    //             menuItem.calories = menuItem.options.calories[0];
    //         }
    //     }
    // }

    return (
        <main>
            <div className='worked-on-div'>
                <p>
                    Meals with this icon <i className='fa-solid fa-shuffle'></i> can be customised!
                </p>
            </div>
            <div className='worked-on-div-mobile'>
                <p>
                    Tap on meals with this icon <i className='fa-solid fa-shuffle'></i> to customise them!
                </p>
            </div>
            <div className='top-buttons-div'>
                <button className='back-button' onClick={() => history.push('/')}>
                    <i className='fa fa-arrow-left'></i>Go Back
                </button>
                {/* <select>
                    <option>{getMealPlan().title}</option>
                </select> */}
                <button className='meal-plan-cart-button' onClick={() => addMealPlanToCart(false)}>
                    <i className='fa fa-cart-plus'></i>Add To Cart
                </button>
            </div>
            <div className='selected-meal-plan-details-div'>
                <p>Selected Meal Plan</p>
                <h1>{getMealPlan().title}</h1>
                <h4>{getMealPlanCalories()}</h4>
            </div>
            {/* <div className='in-dev-div'>
                <h2>
                    <i className='fa-solid fa-circle-exclamation'></i> Orders through the website are currently disabled!
                </h2>
                <h2>The menu is being worked on and will be available 1 March, 2024!</h2>
                <h3>Thank you for your patience! ❤️</h3>
            </div> */}
            <Menu openItemDetails={openItemDetails} mealPlanItem={getMealPlan()} />
            <MenuItemPopUpDetails
                closeItemDetails={closeItemDetails}
                mealPlanId={itemDetailsPopUp.mealPlanId}
                mealConsumptionType={itemDetailsPopUp.mealConsumptionType}
                item={itemDetailsPopUp.item}
            />
            <ThankYouPopUp
                mealPlan={thankYouPopUp}
                closePopUp={() => setThankYouPopUp(null)}
                goToCart={() => history.push('/cart')}
                orderExtra={() => history.push('/extra')}
            />
            <DoubleItemInCartPopUp
                isDisplayed={doubleItemInCartPopUp}
                mealPlanName={getMealPlan().title}
                handleAddOneMore={addOneMore}
                closePopUp={() => setDoubleItemInCartPopUp(false)}
            />
            <Footer />
        </main>
    );
});

const mapStateToProps = createStructuredSelector({
    cartCount: selectCartItemsCount,
    cartList: selectCartItems,
});

const mapDispatchToProps = (dispatch) => ({
    cartAddItem: (item) => dispatch(cartAddItem(item)),
    cartRemoveItem: (item) => dispatch(cartRemoveItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MealPlanMenu);
