import { MealPlanActionTypes } from "./mealplan.actionTypes";

export const setCustomizedMenu = (mealPlanId, menu) => ({
    type: MealPlanActionTypes.SET_CUSTOMIZED_MENU,
    payload: { mealPlanId, menu }
});

export const updateCustomizedMenuItem = (mealPlanId, mealType, mealFamily, updatedItem) => ({
    type: MealPlanActionTypes.UPDATE_CUSTOMIZED_MENU_ITEM,
    payload: { mealPlanId, mealType, mealFamily, updatedItem }
});

export const clearCustomizedMenu = () => ({
    type: MealPlanActionTypes.CLEAR_CUSTOMIZED_MENU
});